.navbar-dark .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0;
}

.top-nav-action {
    margin-top: auto;
    margin-bottom: auto;
}

.top-nav-button {
    margin-left: 8px;
}

.sidebar {
    width: 400px;
    margin-right: 8px;
    margin-left: 8px;
    min-height: 100vh;
    padding: 16px 0 0 0;
}

.sidebar.toggled {
    width: 100px;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.round-button {
    border-radius: 50%;
    padding: 8px 10px;
}

/*@media (min-width: 768px) {*/
/*    .sidebar {*/
/*        width: 400px !important;*/
/*    }*/
/*}*/


.wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.shell-container {
    margin-top: 3.5rem;
    padding: 0
}

.footer {
    padding: 30px 45px;
    max-width: 720px;
}

.footer-links {
    padding-left: 0;
    margin-bottom: 16px;
}

.footer-links li:first-child {
    padding-left: 0;
}

.footer-links li {
    display: inline;
    padding: 0 2px;
}

.footer-links li.footer-links-divider {
    margin-top: auto;
    margin-bottom: auto;
}

.close {
    color: inherit !important;
}

.divider {
    width: 100%;
}

.help-content {
    max-width: 800px;
    margin: 16px auto 64px auto;
}

/*NavListItem styles*/
.gd-nav-item {
    margin-top: 0 !important;
}

@media (min-width: 768px) {
    .gd-nav-item.gd-nav-list-item[aria-expanded=true]::after {
        content: '\f107';
    }

    .gd-nav-item.gd-nav-list-item::after {
        width: 1rem;
        text-align: center;
        float: right;
        vertical-align: 0;
        border: 0;
        font-weight: 900;
        content: '\f105';
        font-family: 'Font Awesome 5 Free';
    }
}


.clickable {
    cursor: pointer
}

.non-clickable {
    cursor: auto;
}

.default-cursor {
    cursor: default;
}

.inboxColor {
    color: #1565c0;
}

.inbox-background {
    background-color: #1565c0;
}

.historyColor {
    color: #00695c;
}

.history-background {
    background-color: #00695c;
}

.favoritesColor {
    color: #ef6c00;
}

.favorites-background {
    background-color: #ef6c00;
}

.display-grid {
    display: grid
}

.account-title {
    margin-bottom: 1.5rem;
}

.component-margin {
    margin-bottom: 16px;
}

.table-lg th,
.table-lg td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.no-top-border {
    border-top: none !important;
}

.no-wrap {
    white-space: nowrap
}

.is-invalid .invalid-feedback {
    display: block !important;
}

.settings-block {
    margin-bottom: 32px;
}

.upload-file {
    padding: 40px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.close-tag {
    margin-left: 4px;
    opacity: 0.5;
}

.close-tag:hover {
    opacity: 1;
}

.tags-field {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.tags-field-input {
    padding: 0 4px !important;
    height: auto !important;
    /*border: none;*/
    outline: 0;
    margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.tags-field-tag {
    font-size: 100%;
    font-weight: normal;
    /*margin-left: 4px;*/
    /*margin-top: 4px;*/
}

.chip-input {
    transition: border 0.15s linear, box-shadow 0.15s linear;
}

.shadow-primary {
    /*box-shadow: 0 0 0 0.2rem var(--react-chip-input__box-shadow-color, var(--primary, blue));*/
    /*box-shadow: 0 0 0 1px red;*/
    /*rgba($color, .25);*/
}

/*.chip-input .chip {*/
/*    transform: translateX(10px);*/
/*    opacity: 0;*/
/*    padding: 2px 4px 3px 8px;*/
/*    border: 1px solid var(--react-chip-input__chip-border-color, var(--primary, blue));*/
/*    border-radius: 16px;*/
/*    height: 32px;*/
/*    transition: box-shadow 0.15s linear, background-color 0.15s linear;*/
/*}*/

/*.chip-input .chip:hover,*/
/*.chip-input .chip:focus,*/
/*.chip-input .chip:active {*/
/*    background-color: var(--react-chip-input__chip-hover-color, rgba(0, 0, 0, 0.05));*/
/*}*/

/*.chip-input .chip.show {*/
/*    animation: move-left-in 0.25s ease-in-out forwards;*/
/*}*/

/*.chip-input .chip.hide {*/
/*    animation: move-right-out 0.25s ease-in-out forwards;*/
/*}*/

.chip-input .chip svg {
    transition: height 0.15s linear, width 0.15s linear;
    transform: scale(0.8);
    margin-top: -2px;
}

.chip-input .chip svg:hover,
.chip-input .chip svg:focus,
.chip-input .chip svg:active {
    transform: scale(1);
}

.chip-input .chip.show {
    animation: move-left-in 0.25s ease-in-out forwards;
}

.chip-input .chip.hide {
    animation: move-right-out 0.25s ease-in-out forwards;
}

.no-focus:focus {
    box-shadow: none !important;
}

.chip-wrapper {
    margin: 0.25rem !important;
}

.reader-card {
    margin-top: 24px;
    margin-bottom: 24px;
}

.reader-typography-0 {
    font-family: Georgia, serif
}

.reader-typography-1 {
    font-family: Arial, Helvetica, sans-serif
}

.reader-text-size-title-0 {
    font-size: 1.3125rem
}

.reader-text-size-title-1 {
    font-size: 1.70625rem
}

.reader-text-size-title-2 {
    font-size: 2.218125rem
}

.reader-text-size-body-0 {
    font-size: 0.875rem
}

.reader-text-size-body-1 {
    font-size: 1.1375rem
}

.reader-text-size-body-2 {
    font-size: 1.47875rem
}

.reader-image {
    max-width: 100%
}

.reader-image-typography {
    margin-top: 16px;
    margin-bottom: 16px;
}


.spinner {
    margin: 50px auto 0;
    /*width: 70px;*/
    text-align: center;
}

.spinner > div {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-right: 4px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.56s infinite ease-in-out both;
    animation: sk-bouncedelay 1.56s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
    background-color: #00b39f;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: #ffb060;
}

.spinner .bounce3 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: #ff5954;
}

.spinner .bounce4 {
    background-color: #1f4c59;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.bookmark-card {
    /*margin-bottom: 16px;*/
    padding: 8px;
}

.bookmark-paragraph {
    margin-bottom: 0;

    /*white-space: nowrap;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*flex: 1;*/

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bookmark-body {
    overflow: hidden;
}

.bookmark-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.small-bookmark-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 0.25rem !important;
}

.bookmark-brief {
    border: 1px solid transparent;
    position: relative;
}

.bookmark-brief:hover {
    border-radius: .25rem !important;
    border: 1px solid #dee2e6 !important;
}

.bookmark-brief:hover .quick-access-panel {
    display: inline-flex !important;
}

.bookmark-expanded {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.animated.speed-200ms {
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
}

.bookmark-indicators {
    padding: 0;
    position: absolute;
    z-index: 1;
    opacity: 0.5;
    right: 8px;
    top: 8px;
}

.bookmark-expanded .bookmark-indicators {
    top: -5px;
    right: -5px;
}

.quick-access-panel {
    padding: 0;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;
    display: none
}

.access-panel-button {
    border-radius: 0;
    padding: .375rem .5rem;
}

.indicator-button {
    border-color: transparent !important;
}

.quick-access-panel-dropdown, .quick-access-panel-dropdown button {
    border-radius: 0;
    box-shadow: none !important;
}

.quick-access-panel-dropdown button.dropdown-toggle::after {
    display: none;
}

.bookmark-selector {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 32px;
    height: 32px;

}

.bookmark-selector-show {
    display: inline-flex;
}

.bookmark-icon-placeholder {
    position: relative;
}

.bookmark-icon-placeholder:hover .bookmark-selector {
    display: block
}

.bookmark-selector .custom-checkbox {
    width: 100%;
    margin-left: 8px;
    margin-top: 5px;
}

.bookmarks-selected .bookmark-brief .quick-access-panel {
    display: none !important
}

.bookmarks-selected .bookmark-selector {
    display: block !important
}

.bg-selected {
    background-color: #bbdefb !important;
}

.bookmark-block {
    margin-bottom: 1rem;
}

.tags-panel,
.bookmark-tags {
    line-height: 1.75
}

.bookmark-tags:hover,
.bookmark-title:hover {
    background-color: #ecf0f1 !important;
    cursor: text;
}

.tags-panel .badge,
.bookmark-tags .badge {
    margin-right: 0.5rem;
    font-size: 100%;
    font-weight: normal
}

.gd-nav-item .dropdown-menu {
    min-width: 400px;
    padding: 0.5rem;
}

.embed-readability {
    max-height: 200px;
    overflow: hidden;
    position: relative;
}

.embed-readability:after {
    content: "";
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .03));
}

.readability img {
    max-width: 100%;
}

#toTop {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.pulse {
    animation: animate 1500ms linear;
    position: relative;
}

@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 #1565c0, 0 0 0 0 #1565c0;
        z-index: 1;
    }

    40% {
        box-shadow: 0 0 0 25px rgba(255, 0, 64, 0), 0 0 0 0 #1565c0;
        z-index: 1;
    }

    80% {
        box-shadow: 0 0 0 25px rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
        z-index: 1;
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
        z-index: 1;
    }
}

.close-button {
    position: relative;
    z-index: 10000;
}

.bg-failed {
    background-color: #fad3cf;
}

.bg-to-extraction {
    background-color: #fde6c9;
}

.reco-hr {
    float: right;
    top: 0;
    margin-top: -0.5em;
    line-height: 1em;
    padding-left: 15px;
    background-color: white;
}

.button-icon {
    margin-right: 3px;
}

.gd-nav-item .dropdown-menu.show {
    position: absolute;
    left: 0;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}